/* 
  Common styles
*/

html,
body {
  overflow-x: hidden !important;
  -webkit-animation: fadein 1s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 1s; /* Firefox < 16 */
   -ms-animation: fadein 1s; /* Internet Explorer */
    -o-animation: fadein 1s; /* Opera < 12.1 */
       animation: fadein 1s;
       animation: fadein 1s;
}
@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}

@keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}
html,
body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
ol,
ul,
li,
a,
blockquote,
pre,
hr,
figure,
fieldset,
legend,
textarea,
th,
td {
  font-family: 'Work Sans', sans-serif;
}

.custom-container {
  max-width: 1225px;
  width: 100%;
  padding: 0 15px;
  margin: 0 auto;
}

.text-primary {
  color: #0047ff !important;
}

.bg-gradient {
  background: linear-gradient(257.98deg, #0047ff 9.06%, #030303 101.32%) !important;
}

.bg-gradient-1 {
  background: rgb(255,255,255);
  background: linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(209,215,229,1) 58%, rgba(0,35,125,0.5200674019607843) 100%);
  min-height: 646px;
  height: 100%;
  display: flex;
  align-items: center;
}

.hero-bg {
  background-position: center !important;
  background-size: cover !important;
}
.hero-bg.avif {
  background-image: url("/src/assets/hero-bg.jpg?as=avif") !important;
}
.hero-bg.no-avif {
  background-image: url("/src/assets/hero-bg.jpg") !important;
}

.faq-banner .hero-bg.avif {
  background-image: url("/src/assets/faq-hero-bg.png?as=avif") !important;
}

.contact-banner .hero-bg.avif {
  background-image: url("/src/assets/contact-hero-bg.jpg?as=avif") !important;
}

.disclaimer-banner .hero-bg.avif {
  background-image: url("/src/assets/legal-hero-bg.jpg?as=avif") !important;
}

.nav-link.active {
  font-weight: bold;
  border-bottom: 3px solid white;
}
header {
  padding-top: 60px;
}
.logo {
  width: 175px;;
}
#navbar {
  padding-bottom: 0px;
  z-index: 999;
  background: #0047FF;
  transition: background-color 300ms ease;
  height: 80px;
  display: flex;
  align-items: center;
}
#navbar header {
  padding-top: 0px;
}
#navbar.transparent header {
  padding-top: 0px;
}
#nav-desktop a {
  margin-right: 25px;
  font-size: 15px;
  font-weight: 700;
  line-height: 40px;
}
.main-banner {
  height: 695px;
}
.main-banner h1 {
  position: relative;
  z-index: 1;
  letter-spacing: 2px;
  font-weight: 900;
  position: relative;
  top: 27px;
  left: -44px;
}
.main-banner .translate-middle {
  transform: translate(-55%,-46%)!important;
}
.web-3-bg {
  background: url("/src/assets/web-3-bg.png") no-repeat 0;
  background-size: cover;
  height: 570px;
}
.web-3-bg .web-3-grid {
  display: grid;
  grid-template-columns: 1fr 500px;
  grid-gap: 30px;
  height: 89%;
  justify-content: center;
  align-items: center;
}
.web-3-bg .web-3-grid picture img {
  width: 100%;
  max-width: 420px;
}
.web-3-bg .web-3-grid h2 {
  font-size: 36px;
  color: white;
  letter-spacing: -0.025em;
  font-weight: 800;
}
.web-3-bg .web-3-grid .crpto {
  max-width: 600px;
  width: 100%;
}
.web-3-bg .web-3-grid p {
  font-size: 16px;
  letter-spacing: -0.025em;
  color: white;
  line-height: 26px;
}
.prepare-btn {
  position: relative;
  top: 32px;
}

.crypto-concept-grid {
  display: grid;
  grid-template-columns: 500px 1fr;
  grid-gap: 110px;
  align-items: center;
  justify-content: space-between;
}
.zigzag-img {
  position: relative;
}
.zigzag-img .diamond-object {
  position: absolute;
  top: -111px;
  left: 260px;
}
.crpto-concept-heading {
  display: flex;
  flex-direction: column;
  margin-top: -60px;
}
.crpto-concept-heading h3,
.crpto-concept-heading h5 {
  font-size: 22px;
  font-weight: 500;
  letter-spacing: -0.025em;
  color: #1D1D1D;
  text-shadow: 0px 0px 0px rgba(255, 255, 255, 0.01);
}
.crpto-concept-heading h3 {
  font-weight: 700;
  font-size: 36px;
  line-height: 42px;
}
.crpto-concept-heading .btn.btn-primary {
  color: #0047FF;
  border-color: #0047FF;
}
.crpto-concept-heading .prepare-btn {
  top: 40px;
}
.services-section h2,
.seen-in-section h2 {
  font-size: 28px;
  font-weight: 800;
  letter-spacing: -0.025em;
  margin-bottom: 50px;
  margin-top: 35px;
}
.services-grid {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-gap: 42px 21px;
}
.services-grid .service-tile {
  height: 118px;
}
.services-grid .service-tile img {
  width: 100%;
  height: 100%;
}
.services-grid .card-body {
  padding: 36px;
  background: #F2F2F2;
  box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.25);
  border-radius: 0px 0px 10px 10px;
}
.services-grid .card-body p {
  font-size: 14px;
  line-height: 22px;
  letter-spacing: -0.025em;
}
.services-grid .bottom-links {
  font-size: 13px;
}
.iconic-text {
  place-items: flex-start;
}
.iconic-text img {
  margin-top: 6px;
}
.l-height-24 {
  line-height: 24px;
}
.seen-in-section .seen-grid-1 {
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  grid-template-rows: 80px;
  place-content: flex-start;
  grid-gap: 40px;
}
.seen-in-section h2 {
  margin-top: 110px;
}
.testiMonial .slide-grid {
  display: grid;
  grid-template-columns: 1fr 35%;
  height: 466px;
  position: relative;
}
.testiMonial .slide-grid .gray-bg {
  background: linear-gradient(279.48deg, #00237D -109.25%, rgba(249, 249, 249, 0) 100%);
  box-shadow: 0px 0px 0px 4px rgba(255, 255, 255, 0.01);
}
.testiMonial .slide-grid .blue-bg {
    background-image: url("/src/assets/testimonial.png");
    position: relative;
}
.testimonial-container {
  max-width: 1225px;
  width: 100%;
  margin: 0 auto;
  padding: 0 15px;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 50%;
  transform: translateY(-80%);
}
.testimonial-container .slider,
.carousel-item {
  letter-spacing: -.025em;
  color: #1d1d1d;
  text-shadow: 0 0 #ffffff03;
  font-size: 22px;
  font-weight: 300;
  line-height: 34px;
  width: 100%;
}
.testimonial-container .client {
  max-width: 780px;
  position: relative;
}
.testiMonial .indicator-arrow {
  margin-top: 0;
  position: absolute;
  top: auto;
  right: 0px;
  bottom: 0;
}
.testiMonial .arrow-btn {
  width: 80px;
  display: flex;
  justify-content: space-between;
}
.testi-logo {
  width: 100px;
}
.testi-logo.dopex {
  width: 50px;
}
.yourIdea {
  background: url("/src/assets/idea-bg.png") center center;
  background-size: cover;
  height: 372px;
  width: 100%;
}
.yourIdea h2 {
  font-weight: 900;
  font-size: 54px;
  line-height: 72px;
  letter-spacing: -0.025em;
  color: #FFFFFF;
  right: 158px;
  top: 10px;
  position: relative;
}
.yourIdea h2 a {
  text-decoration: none;
  color: white;
}
.footer {
  padding: 60px 0;
  background: linear-gradient(-45deg,  #0047FF, #00237b, #020D3B, rgb(0, 69, 181));
  background-size: 400% 400%;
  animation: gradient 15s ease infinite;
}
.footer .f-logo {
  width: 141px;
  margin-top: 4px;
}
.financial .footer-info {
  transform: none;
}
.footer .f-logo img {
  width: 100%;
}
.footer .footer-col-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 180px;
  padding-top: 10px;
  align-items: flex-end;
}
.footer .footer-info {
  font-size: 12px;
  line-height: 22px;
  color: #FFFFFF;
  text-shadow: 0px 0px 0px rgba(255, 255, 255, 0.01);
  font-weight: 300;
  max-width: 100%;
  max-width: 300px;
  width: 100%;
}
.footer .all-rights {
  color: white;
  margin-top: 0;
  font-size: 12px;
}
.footer .all-rights ul {
  display: grid;
  grid-auto-flow: column;
  grid-gap: 15px;
  padding: 0;
  margin: 0;
  place-content: flex-end;
}
.footer .all-rights ul li {
  list-style: none;
}
.footer .all-rights ul li a {
  text-decoration: none;
  font-size: 12px;
  line-height: 24px;
  color: #FFFFFF;
  text-shadow: 0px 0px 0px rgba(255, 255, 255, 0.01);
}
.footer .all-rights .reserved {
  display: flex;
  place-content: flex-end;
}

@keyframes slideInFromLeft {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
}

.slide-in-from-left {
  animation: slideInFromLeft 0.5s ease-in-out;
}

@keyframes slideOutToLeft {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}
.slide-out-to-left {
  animation: slideOutToLeft 0.5s ease-in-out;
}

.mobile-nav {
  z-index: 99999999;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(257.98deg, #0047ff 9.06%, #030303 101.32%) !important;
}

.text-small {
  font-size: 0.8rem;
}

.transparent {
  background-color: transparent !important;
  border: none !important;
}

.bg-primary {
  background-color: #0047ff !important;
}
.bg-black {
  background-color: #000 !important;
}
.bg-light {
  background-color: #f2f2f2 !important;
}

.btn.btn-primary {
  background-color: transparent;
  color: white;
  border: 1px solid white;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 43px;
  display: flex;
  align-items: center;
  letter-spacing: 0;
  font-size: 14px;
  letter-spacing: -0.025em;
  max-width: 236px;
  box-shadow: 0px 0px 0px 4px rgba(255, 255, 255, 0.01);
}

.btn.btn-primary:hover {
  background-color: #0047ff !important;
  color: #fff !important;
}

.btn.bg-black.btn-dark:hover {
  background-color: #fff !important;
  color: #000 !important;
}

.btn.bg-black.btn-dark:active {
  background-color: #fff !important;
  color: #000 !important;
  transform: translateY(1px);
}

/* Homepage */

.object-0 {
  z-index: 0;
}

.object-1 {
  top: 25%;
}

.object-2 {
  height: 204px;
  width: 81px;
  position: absolute;
  left: 0;
  top: 25%;
}

.object-3 {
  position: absolute;
  top: -76px;
  right: 5%;
}

.object-4 {
  position: absolute;
  bottom: -137px;
  left: -1%;
}

.object-5 {
  width: 171px;
  height: 171px;
}

.bg-section1 {
  background-image: url("/src/assets/bg-section-1.png") !important;
  background-position: center !important;
  background-size: cover !important;
}

.bg-section2 {
  background-position: center !important;
  background-size: cover !important;
}
.bg-section2.no-avif {
  background-image: url("/src/assets/bg-section-2.png") !important;
}
.bg-section2.avif {
  background-image: url("/src/assets/bg-section-2.png?as=avif") !important;
}

.as-seen-img {
  width: 10rem;
  height: 5rem;
  object-fit: contain;
  object-position: center;
  margin: 0.7rem;
}

.bg-testimonial {
  background-position: center !important;
  background-size: cover !important;
}
.bg-testimonial.no-avif {
  background-image: url("/src/assets/testimonial.png") !important;
}
.bg-testimonial.avif {
  background-image: url("/src/assets/testimonial.png?as=avif") !important;
}

/* About */
.bg-about-section-1 {
  background-image: url("/src/assets/about-bg-section-1.png") !important;
  background-position: center !important;
  background-size: cover !important;
}

.bg-about-section-2 {
  background-image: url("/src/assets/about-bg-section-2.jpg") !important;
  background-position: center !important;
  background-size: cover !important;
}

.process-card {
  background: #F2F2F2;
  box-shadow: 0px 0px 0px 4px rgba(255, 255, 255, 0.01);
  border-radius: 0px 0px 10px 10px;
  border: 0;
}

/* FAQs */

.faq {
  border-top: 1px solid #d9d9d9;
}
.faq:last-child {
  border-bottom: 1px solid #d9d9d9;
}

/* Contact */
.border-bottom {
  border-bottom: 1px solid #d9d9d9;
}

.faq-banner {
  background: url("/src/assets/faq-banner.png") no-repeat;
  background-size: cover;
  height: 524px;
  margin-bottom: 83px;
}
.faq-banner h1 {
  top: 19px;
  left: 0;
}
.faq-banner .object-0 {
  max-width: 430px;
  width: 100%;
}

.faq-banner .object-2 {
  width: 128px;
  top: 39%;
  left: 0;
  height: auto;
}
.faq-banner .object-1 {
  width: 105px;
  top: 13%;
}
.faq-tile-grid {
  grid-gap: 30px;
  border-top: 1px solid #d9d9d9;
  grid-template-columns: 38% 1fr;
  padding: 41px 0;
  display: grid;
}
.brdr-btm {
  border-bottom: 1px solid #d9d9d9;
}
.faq-tile-grid h4 {
  letter-spacing: -.025em;
  color: #0047ff;
  margin: 0;
  font-size: 22px;
  font-weight: 600;
  line-height: 28px;
  max-width: 340px;
}
.faq-tile-grid p {
  letter-spacing: -.025em;
  color: #1d1d1d;
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  margin: 0;
  padding-top: 5px;
}
.faq-page .faq-contact-btn {
  margin: 70px 0 200px 0;
}
.faq-page .btn.btn-primary {
  border-color: #0047FF;
  color: #0047FF;
  min-width: 344px;
}
.contact-banner {
  height: 530px;
  margin-bottom: 125px;
}
.contact-page .contact-banner.main-banner .object-0 {
  max-width: 800px;
}
.contact-page .contact-banner.main-banner .translate-middle  {
  transform: translate(-50%, -47%) !important;
}
.contact-page .contact-banner.main-banner .object-2 {
  width: 205px;
  top: 30%;
}
.contact-page .contact-banner.main-banner .object-1 {
  width: 230px;
  top: 10%;
  height: auto;
}
.contact-page .contact-grid {
  display: grid;
  grid-template-columns: 38% 1fr;
  grid-gap: 30px;
}
.contact-page .contact-grid form {
  margin-top: 6px;
}
.contact-page .contact-grid h4 {
  letter-spacing: -.025em;
  color: #0047ff;
  margin: 0;
  font-size: 24px;
  font-weight: 600;
  max-width: 310px;
  line-height: 31px;
}
.contact-page .contact-grid p {
  letter-spacing: -.025em;
  color: #1d1d1d;
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  padding-top: 5px;
  margin-top: 13px;
  max-width: 320px;
}
.contact-page .contact-grid .form-floating label {
  font-size: 16px;
  color: #0047FF;
  top: -3px;
}
.contact-page .contact-grid .form-floating .form-control {
  height: 50px;
  line-height: normal;
  padding: 15px 8px 5px 8px;
  border-color: #0047FF;
}
.form-floating>.form-control:focus~label {
  transform: scale(.50) translateY(-0.5rem) translateX(0.15rem);
}
.contact-page .contact-grid form .btn.btn-primary {
  border-color: #0047FF;
  color: #0047FF;
  min-width: 243px;
}
.contact-page .contact-grid .faq-contact-btn {
  margin: 33px 0px 102px 0;
}
.disclaimer-page .disclaimer-banner {
  height: 298px;
  margin-bottom: 73px
}
.disclaimer-page .disclaimer-banner h1 {
  font-weight: 600;
  font-size: 30px;
  line-height: 24px;
  letter-spacing: normal;
  top: 36px;
  left: 0;
}
.disclaimer-page h3 {
  font-weight: 600;
  font-size: 22px;
  letter-spacing: -0.025em;
  color: #081e58;
  border-bottom: 1px solid #D9D9D9;
  padding-bottom: 20px;
  margin-bottom: 34px;
}
.disclaimer-content {
  padding-bottom: 50px;
}
.disclaimer-content p {
  font-size: 14px;
  line-height: 22px;
  letter-spacing: -0.025em;
  color: #1D1D1D;
  margin-bottom: 22px;
}
.tos-page {
  padding-bottom: 50px;
}
.tos-content p {
  font-size: 14px;
  font-weight: normal;
}
.tos-content ol {
  list-style-type: none;
  counter-reset: item;
  margin: 0;
  padding: 0px;
}

.tos-content ol li {
  display: table;
  counter-increment: item;
  margin-bottom: 0.6em;
  color: #1D1D1D;
  padding: 2px 0 ;
  font-size: 14px;
}

.tos-content ol li:before {
  content: counters(item, ".") ". ";
  display: table-cell;
  padding-right: 0.6em;    
}

.tos-content li ol li {
  margin: 0;
}

.tos-content li ol > li:before {
  content: counters(item, ".") " ";
}
.fbold {
  font-weight: bold;
}
.tos-content ol ol ol {
  padding-left: 10px;
}
.tos-content ol ol.m {
  list-style-type: lower-latin;
  padding-left: 20px;
}
.tos-content ol ol.n {
  list-style-type: lower-roman;
  padding-left: 45px;
}
.tos-content ol ol.n li,
.tos-content ol ul li {
  display: revert;
  counter-increment: auto;
}
.tos-content ol ol.n li:before,
.tos-content ol ul li:before {
  display: none;
}
.tos-content ol ul {
  list-style-type: disc;
}

/* About Page */

.about-page .about-banner {
  height: 700px;
}
.about-page .about-banner .hero-bg {
  background: url("/src/assets/about-section-1.jpg") no-repeat center;
}
.about-page .about-banner .object-0 {
  width: 710px;
  height: auto;
  transform: translate(-50%, -48%) !important;
}
.about-page .about-banner .object-2 {
  width: 126px;
  height: auto;
  top: 26.4%;
}
.about-page .about-banner .object-1 {
  top: 50%;
  width: 216px;
}
.about-page .about-banner h1 {
  display: flex;
  flex-direction: column;
  left: -123px;
  top: 44px;
  letter-spacing: normal;
  font-weight: 700;
}
.about-page .about-banner h1 .item-1 {
  position: relative;
  left: 198px;
  top: -10px;
}
.about-page .about-banner h1 .item-2 {
  position: relative;
  left: 30px;
  top: -24px;
}
.about-page .im-crypto {
  background: url("/src/assets/about-section-2.png") no-repeat center;
  height: 428px;
  background-size: cover;
}
.about-page .im-crypto .banner-content h6 {
  font-weight: 600;
  font-size: 18px;
  line-height: 12px;
  text-align: center;
  letter-spacing: -0.025em;
  color: #FFFFFF;
  margin-bottom: 15px;
}
.about-page .im-crypto .banner-content h2 {
  font-weight: 900;
  font-size: 100px;
  line-height: 72px;
  text-align: center;
  letter-spacing: -0.025em;
  color: #FFFFFF;
  position: relative;
  top: 2px;
  left: -9px;
}
.about-page .im-crypto .banner-content p {
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  text-align: center;
  letter-spacing: -0.025em;
  color: white;
  position: relative;
  top: 9px;
  margin: 0 auto;
  max-width: 500px;
}
.about-page .cryptography {
  background: url("/src/assets/about-section-3.png") no-repeat center;
  height: 354px;
  background-size: cover;
}
.about-page .cryptography .object-4 {
  width: 320px;
  bottom: 0;
  transform: translate(40%, 54%);
  top: 0;
  left: 0;
}
.about-page .cryptography h2 {
  font-weight: 600;
  font-size: 54px;
  line-height: 72px;
  text-align: center;
  letter-spacing: -0.025em;
  color: #FFFFFF;
}
.about-page .our-team .crypto-concept-grid {
  grid-template-columns: 1fr 415px;
  max-width: 950px;
  margin: 0 auto;
  grid-gap: 20px;
}
.about-page .our-team .bg-gradient-1 {
  min-height: 520px;
}
.about-page .our-team .bg-gradient-1 .crpto-concept-heading {
  flex-direction: column;
  margin-top: 0;
  display: flex;
  letter-spacing: -0.025em;
  line-height: 26px;
  position: relative;
  top: -13px;
}
.about-page .our-team .bg-gradient-1 .crpto-concept-heading .prepare-btn {
  top: 12px;
}
.about-page .our-team .bg-gradient-1 .zigzag-img {
  position: relative;
  min-width: 495px;
}
.about-page .our-team .bg-gradient-1 .zigzag-img img {
  position: relative;
  top: -3px;
  left: -9px;
}
.work-with-us h2 {
  font-weight: 600;
  font-size: 28px;
  line-height: 64px;
  letter-spacing: -0.025em;
  margin: 73px 0 32px;
  color:  #0047FF;
}
.work-with-us .work-grid {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  grid-gap: 80px;
  margin-bottom: 88px;
}
.work-with-us .work-tile {
  background: #F3F2F2;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.25);
  border-radius: 0px 0px 10px 10px;
}
.work-with-us .work-tile .tile-content {
  padding: 26px 29px 16px 28px;
}
.work-with-us .work-tile .tile-content h4 {
  font-weight: 800;
  font-size: 22px;
  line-height: 28px;
  letter-spacing: -0.025em;
  color: #0047FF;
}
.work-with-us .work-tile .tile-content p {
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.025em;
  color: #1D1D1D;
}
.zigzag-img-mobile,
.mobile-ring,
.ipad-seen {
  display: none;
}

@media (max-width: 1366px) {
  .footer .footer-col-grid {
    padding-top: 10px;
    display: flex;
    justify-content: space-between;
  }
  .footer .all-rights {
    align-items: flex-start !important;
  }
}

@media (max-width: 1192px){
  .seen-grid-1 img {
      width: 100%;
  }
}

@media (max-width: 991px) {
  .object-0 {
    width: 350px;
    height: 350px;
  }
  .object-1 {
    height: 163px;
    width: 102px;
    top: 60%;
  }
  .object-2 {
    width: 67px;
    height: 150px;
    top: 10%;
  }

  .object-3 {
    width: 250px;
    height: 250px;
    top: -100px;
    right: 10%;
  }

  .object-4 {
    width: 300px;
    height: 300px;
    bottom: -100px;
    left: 0px;
  }
  .web-3-bg .web-3-grid {
    grid-template-columns: 1fr 50%;
  }
  .crypto-concept-grid {
    grid-template-columns: 50% 1fr;
    grid-gap: 50px;
  }
  .services-grid .service-tile {
    height: auto;
  }
  .services-grid .card-body {
    padding: 20px
  }
  .services-grid .bottom-links {
    grid-gap: 20px;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    display: grid !important;
  }
  .seen-in-section .seen-grid-1 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
    grid-auto-flow: inherit;
  }
  .desktop-seen {
    display: none;
  }
  .ipad-seen {
    display: block;
  }
  .testiMonial .indicator-arrow {
    position: static;
  }
  .testimonial-container {
    inset: 0;
    transform: none;
    position: static;
  }
  .testiMonial .slide-grid {
    grid-template-columns: 1fr;
  }
  .testiMonial .slide-grid .gray-bg {
    display: none;
  }
  .testimonial-container {
    transform: none;
    background: linear-gradient(279.48deg, #00237d -109.25%, #f9f9f900 100%);
    box-shadow: 0 0 0 4px #ffffff03;
    padding: 80px 15px 40px 15px;
  }
  .testiMonial .slide-grid {
    height: auto;
  }
  .testiMonial .slide-grid .blue-bg {
    height: 390px;
    background-size: cover;
  }
  .testiMonial .slide-grid .gray-bg {
    height: 420px;
  }
}

@media (max-width: 768px) {
  .nav-link.active {
    border-bottom: 1px solid white !important;
  }
}

@media (max-width: 767px) {
  .logo {
    width: 130px;
  }
  #navbar {
    padding-bottom: 0px;
    height: 50px;
  }
  .main-banner {
    height: 550px;
  }
  .main-banner h1 {
    left: 0;
  }
  .web-3-bg {
    height: 100%;
    background-position: 70% 0;
    padding: 80px 0;
  }
  .web-3-bg .web-3-grid {
    display: flex;
    flex-direction: column;
    flex-flow: wrap-reverse;
    height: 100%;
    grid-gap: 0;
  }
  .web-3-bg .web-3-grid picture img {
    max-width: 300px;
  }
  .web-3-bg .web-3-grid h2 {
    font-size: 28px;
    margin-top: 20px;
  }
  .web-3-bg .web-3-grid p {
    font-size: 13px;
    line-height: 20px;
  }
  .prepare-btn {
    position: static;
  }
  .btn.btn-primary {
    height: 38px;
    max-width: 190px;
    font-size: 13px;
  }
  .bg-gradient-1 {
    padding: 40px 0 80px 0;
  }
  .zigzag-img img {
    max-width: 340px;
  }
  .zigzag-img .diamond-object {
    left: 190px;
    top: -71px;
    width: 110px;
  }
  .crypto-concept-grid {
    display: flex;
    flex-direction: column;
    grid-gap: 0;
  }
  .crpto-concept-heading {
    margin-top: 60px;
  }
  .crpto-concept-heading h5 {
    font-size: 18px;
    font-weight: 600;
  }
  .crpto-concept-heading h3 {
    font-size: 24px;
    font-weight: 300;
    line-height: 26px;
  }
  .services-section h2, .seen-in-section h2 {
    margin-top: 0;
    margin-bottom: 20px;
    font-size: 24px;
    font-weight: 600;
  }
  .services-grid .service-tile {
    height: auto;
  }
  .services-grid {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
  .services-grid .card-body {
    padding: 20px;
  }
  .services-grid .bottom-links {
    display: grid !important;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-gap: 20px;
  }
  .object-3 {
    top: -60px;
  }
  .testimonial-container .slider {
    font-size: 22px;
    line-height: 34px;
  }
  .yourIdea {
    background: url("/src/assets/idea-bg-mobile.png") center center;
    background-size: cover;
    width: 100%;
    height: 510px;
  }
  .yourIdea h2 {
    font-size: 32px;
    font-weight: 400;
    line-height: 38px;
    width: 230px;
    text-align: center;
    left: 0;
    top: 110px;
  }
  .yourIdea h2 a {
    color: white;
    font-weight: 600;
    font-style: italic;
  }
  .yourIdea .ideatext {
    justify-content: center !important;
  }
  .footer {
    padding: 25px 0;
  }
  .footer .footer-col-grid {
    display: flex;
    grid-gap: 10px;
    flex-wrap: wrap;
  }
  .footer-info.management { 
    max-width: 100%;
    flex: 100%;
  }
  .footer-info {
    flex: 50%;
  }
  .dotcom {
    flex: 1;
  }
  .dotcom img {
    width: 120px;
  }
  .footer .all-rights {
    margin: 0;
  }
  .footer .all-rights,
  .footer .all-rights ul li a {
    font-size: 12px;
    align-items: center;
    place-self: flex-start;
  }
  .footer .f-logo {
    width: 140px;
  }
  .l-height-24 {
    line-height: 30px;
  }
  .faq-banner {
    height: 470px;
    margin-bottom: 50px;
  }
  .faq-tile-grid {
    padding: 20px 0;
  }
  .faq-tile-grid {
    grid-template-columns: 1fr;
    grid-gap: 5px;
  }
  .faq-tile-grid h4,
  .contact-page .contact-grid h4 {
    font-size: 18px;
    line-height: 22px;
  }
  .faq-tile-grid p,
  .contact-page .contact-grid p {
    padding-top: 0px;
    font-size: 13px;
    line-height: 22px;
  }
  .faq-page .faq-contact-btn {
    margin: 35px 0 35px;
  }
  .faq-page .btn.btn-primary {
    min-width: 190px;
  }
  .contact-page .contact-grid {
    grid-template-columns: 1fr;
  }
  .contact-page .contact-grid h4,
  .contact-page .contact-grid p {
    max-width: 100%;
  }
  .contact-page .contact-banner.main-banner .object-1 {
    width: 100px;
  }
  .contact-page .contact-banner.main-banner .object-2 {
    width: 100px;
    top: 60%;
  }
  .disclaimer-page .disclaimer-banner {
    margin-bottom: 30px;
  }
  .disclaimer-page h3 {
    font-size: 18px;
  }
  .disclaimer-content p {
    font-size: 12px;
    line-height: 18px;
  }
  .about-page .about-banner h1 {
    font-size: 32px;
    font-weight: 600;
  }
  .about-page .about-banner .object-0 {
    width: 400px;
  }
  .about-page .about-banner {
    height: 550px;
  }
  .about-page .about-banner h1 {
    left: 0;
  }
  .about-page .about-banner h1 .item-1 {
    left: 42px;
    top: -3px;
  }
  .about-page .about-banner h1 .item-2 {
    position: relative;
    top: -4px;
    left: 30px;
  }
  .about-page .about-banner .object-1 {
    width: 76px;
    top: 70%;
    height: auto;
  }
  .about-page .about-banner .object-2 {
    width: 46px;
  }
  .about-page .im-crypto {
    height: 472px;
  }
  .about-page .im-crypto .banner-content h6 {
    font-size: 16px;
  }
.about-page .im-crypto .banner-content h2 {
    font-size: 64px;
    font-weight: 900;
    line-height: 54px;
    position: static;
    max-width: 350px;
    margin: 0 auto;
  }
  .about-page .im-crypto .banner-content p {
    font-size: 14px;
    line-height: 22px;
    max-width: 330px;
  }
  .about-page .cryptography h2 {
    font-size: 32px;
    line-height: 40px;
  }
  .about-page .cryptography .object-4 {
    transform: translate(-14%, 60%);
  }
  .about-page .cryptography .object-ring {
    transform: translate(-24%, -156%);
    width: 136px;
  }
  .about-page .our-team .bg-gradient-1 .crpto-concept-heading {
    max-width: 373px;
  }
  .about-page .our-team .bg-gradient-1 .crpto-concept-heading p {
    font-size: 13px;
    line-height: 20px;
  }
  .our-team .bg-gradient-1 {
    padding: 30px 0 60px;
  }
  .crypto-concept-section.bg-gradient-1 {
    padding-bottom: 40px;
  }
  .zigzag-img-mobile,
  .mobile-ring {
    display: block;
  }
  .zigzag-img-mobile img {
    max-width: 320px;
  }
  .work-with-us .work-grid {
    grid-template-columns: repeat(1, minmax(0, 1fr));
    grid-gap: 30px;
  }
  .work-with-us {
    max-width: 373px;
    margin: 0 auto;
  }
  .seen-in-section.desktop-seen,
  .seen-in-section.ipad-seen {
    display: none !important;
  }
  .testimonial-container .slider,
  .carousel-item {
    font-size: 18px;
    line-height: 30px;
  }
}

@media (max-width: 480px) {
    .footer {
      padding: 30px 0;
    }
    .footer .footer-col-grid {
      padding-top: 0px;
    }
    .financial .footer-info {
      padding-left: 0;
    }
    .footer .footer-col-grid,
    .footer .all-rights {
      flex-direction: column;
    }
    .footer .copyrights {
      transform: none;
    }
  }
